/************************
 ** Bootstrap override **
 ************************/

$grid-gutter-width: 60px;

/*****************
 ** Font colors **
 *****************/

$default-opacity: 0.87;
$light-opacity: 0.65;
$hint-opacity: 0.38;

$default-font-color: rgba(0, 0, 0, $default-opacity);
$light-font-color: rgba(0, 0, 0, $light-opacity);
$hint-font-color: rgba(0, 0, 0, $hint-opacity);

/**************
 ** Products **
 **************/

$brand-color: #ff6600;

$assist-color: #9800b2;
$contact-color: #ff0066;
$help-color: #cf0e8f;
$reply-color: #7037dd;
$chatbot-color: #414cf9;
$automator-color: #ff6600;

$products: ();
$products: map-merge($products, (assist: $assist-color));
$products: map-merge($products, (contact: $contact-color));
$products: map-merge($products, (help: $help-color));
$products: map-merge($products, (reply: $reply-color));
$products: map-merge($products, (chatbot: $chatbot-color));
$products: map-merge($products, (automator: $automator-color));

/**********
 ** Menu **
 **********/

$menu-height-xs: 100;
$menu-height-md: 120;

/****************************
 ** Customers/Case Studies **
 ****************************/

/** customer colors */
$customer-menu-color: #ffffff;

$deutsche-bahn-color: #e21322;
$deutsche-bahn-color-light: #e21322;

$globetrotter-color: rgb(195, 90, 88);
$flixbus-color: rgb(51, 153, 0);
$magix-color: rgb(0, 0, 0);
$eth-zuerich-color: rgb(36, 68, 121);
$hoermann-color: rgb(54, 56, 96);
$klingel-color: rgb(97, 105, 108);
$myphotobook-color: rgb(102, 153, 0);

$case-studies: ();

$case-studies: map-merge($case-studies, (deutsche-bahn: $deutsche-bahn-color));
$case-studies: map-merge($case-studies, (globetrotter: $globetrotter-color));
$case-studies: map-merge($case-studies, (flixbus: $flixbus-color));
$case-studies: map-merge($case-studies, (magix: $magix-color));
$case-studies: map-merge($case-studies, (eth-zurich: $eth-zuerich-color));
$case-studies: map-merge($case-studies, (hoermann: $hoermann-color));
$case-studies: map-merge($case-studies, (klingel: $klingel-color));
$case-studies: map-merge($case-studies, (myphotobook: $myphotobook-color));

/**************
 ** Branches **
 **************/

$branch-e-commerce-color: #6353a0;
$branch-industry-color: #005f95;
$branch-marketplace-color: #885E3D;
$branch-public-institutions-color: #616361;
$branch-software-color: #b28600;
$branch-transport-color: #385e1e;

$branches: ();

$branches: map-merge($branches, (e-commerce: #6353a0));
$branches: map-merge($branches, (industry: #005f95));
$branches: map-merge($branches, (marketplace: #885E3D));
$branches: map-merge($branches, (public-institutions: #616361));
$branches: map-merge($branches, (software: #b28600));
$branches: map-merge($branches, (transport: #385e1e));

/**************
 ** About Us Zahlen-Boxen **
 **************/
$statistic-box1: #ffaa00;
$statistic-box2: #ff9900;
$statistic-box3: #ff7300;
$statistic-box4: #ff5f27;


/********************
 ** Other defaults **
 ********************/

$default-box-shadow: 0 0 20px 1px rgba(0,0,0,0.20);
$default-border-radius: 5px;
$default-gray-background-color: #F2F2F2;
