@import "_variables";

@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";

@import "bootstrap/bootstrap-grid";

@import "cookie-consent";

html, body {
  padding: 0;
  margin: 0; }

body {
  background: #fff;

  font-family: Roboto, Helvetica, system-ui, sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: $default-font-color; }

h1, h2, h3, h4, p, a, blockquote, ul, ol {
  margin: 0;
  padding: 0; }

h1 {
  font-family: RobotoSlab, sans-serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 48px;

  margin-top: 100px;
  margin-bottom: 100px; }

h2 {
  font-family: RobotoSlab, sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;

  margin-top: 48px;
  margin-bottom: 28px; }

h3 {
  font-family: RobotoSlab, sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;

  margin-top: 28px;
  margin-bottom: 28px; }

h4 {
  font-family: Roboto, Helvetica, system-ui, sans-serif;
  font-size: 22px;
  font-weight: bold;
  line-height: 34px; }

p {
  font-size: 18px;
  line-height: 32px; }

ol, ul {
  font-size: 18px; }

a, a:visited, a:active, a:hover {
  text-decoration: none;
  color: $default-font-color;
  cursor: pointer; }

a:hover {
  color: $brand-color; }

blockquote {
  font-size: 22px;
  line-height: 34px;
  font-style: italic;

  p {
    font-family: RobotoSlab,sans-serif;
    font-size: 16px;
    line-height: 24px; } }

img {
  width: 100%; }

form {
  margin: 0; }

.button {
  height: 58px;
  line-height: 58px;
  font-size: 18px;
  padding: 0 30px;

  color: $default-font-color;
  border-radius: 5px;
  border: 2px solid $default-font-color;

  cursor: pointer;
  text-align: center;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;

  &:hover {
    opacity: 0.9; } }

.button.primary {
  color: white;
  background: $brand-color;
  border-color: $brand-color; }

.badge {
  font-size: 12px;
  margin-left: 10px;
  background: #f06;
  color: white;
  padding: 0 10px;
  border-radius: 10px;
  white-space: nowrap;
  font-weight: normal;
  line-height: 20px;
  display: inline-block; }

input, textarea {
  height: 58px;
  width: 100%;

  padding: 0 20px;

  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 58px;

  border: 2px solid #9D9D9D;
  border-radius: 5px;

  -webkit-appearance: none; }

textarea {
  resize: none;
  height: auto; }

input[type=submit] {
  color: $brand-color;
  border-color: $brand-color;
  background: none;
  cursor: pointer; }

input[type=submit][disabled=disabled] {
  opacity: 0.3 !important; }

hr {
  margin: 48px 0;
  border: none;
  background: #eee;
  height: 1px; }

.gray-wrapper {
  background: $default-gray-background-color; }

.background-circle {
  width: 60%;
  z-index: 0;
  position: absolute;
  overflow: hidden;
  height: 1500px;
  top: 1050px;
  right: 0;
  margin-top: -700px;

  img {
    right: -20%;
    position: absolute; } }

.background-circle-2 {
  width: 49%;
  z-index: -2;
  position: absolute;
  overflow: hidden;
  height: 1500px;
  left: 0;
  margin-top: -200px;


  img {
    left: -20%;
    position: absolute; } }

.background-circle-3 {
  width: 39%;
  z-index: -2;
  position: absolute;
  overflow: hidden;
  height: 1000px;
  right: 0;
  margin-top: 350px;

  img {
    right: -20%;
    position: absolute; } }

.background-circle-4 {
  width: 39%;
  z-index: -2;
  position: absolute;
  overflow: hidden;
  height: 1000px;
  right: 0;
  margin-top: 500px;

  img {
    right: -10%;
    position: absolute; } }

.row-no-margin {
  margin-left: 0;
  margin-right: 0; }

.text-center {
  text-align: center; }

//@media (min-width: 768px)

@media (min-width: 992px) {
  body {
    font-size: 15px;
    line-height: 26px; }

  h2 {
    font-size: 26px;
    line-height: 38px; }

  h3 {
    font-size: 18px;
    line-height: 22px; }

  h4 {
    font-size: 18px;
    line-height: 28px; }

  p {
    font-size: 15px;
    line-height: 26px; }

  .ol, ul {
    font-size: 15px; }

  blockquote {
    font-size: 15px;
    line-height: 26px;

    p {
      font-family: RobotoSlab,sans-serif;
      font-size: 15px;
      line-height: 24px; } }

  .button {
    height: 52px;
    line-height: 48px;
    padding: 0 30px;
    font-size: 15px; }

  input {
    line-height: 52px;
    height: 48px;
    width: 270px;
    font-size: 16px;
    background: #ffffff; }

  textarea {
    height: auto;
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    padding-top: 14px; }

  .col-md-20-percent {
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 1200px) {
  body {
    font-size: 16px;
    line-height: 26px; }

  h1 {
    font-size: 32px;
    line-height: 46px; }

  h2 {
    font-size: 26px;
    line-height: 38px; }

  h3 {
    font-size: 20px;
    line-height: 26px; }

  h4 {
    font-size: 20px;
    line-height: 32px; }

  p {
    font-size: 16px;
    line-height: 26px; }

  ol, ul {
    font-size: 16px; }

  blockquote {
    font-size: 20px;
    line-height: 32px;

    p {
      font-family: RobotoSlab,sans-serif;
      font-size: 16px;
      line-height: 24px; } }

  .button {
    font-size: 16px; }

  .text-column {
    column-count: 2;
    column-gap: 20px; } }

@media (min-width: 1500px) {
  .d-xxl-block {
    display: block !important; } }

@import "stages";

@media (max-width: 1200px) {
  .container {
    width: 100%;
    max-width: 100%; } }
