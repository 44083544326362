.cookie-consent {
  display: none;

  &__wrapper {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: -120px;
    z-index: 150;
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
    padding: 10px 20px;

    transition: bottom 1s ease-in-out;

    p, a {
      font-size: 13px;
      line-height: 20px;
      color: #707070; }

    a.link-button {
      margin-right: 10px;
      color: #ff6600; } }

  &--active {
    display: block; }

  &--active &__wrapper {
    bottom: 0; }

  &__content {
    flex: 0 1 100%;

    .short {
      display: none; }

    .detailed {
      display: block; } }


  &__actions {
    flex: 0 1 auto;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-left: 40px;

    .button {
      min-width: 150px;
      white-space: nowrap;
      font-size: 13px;
      height: 34px;
      line-height: 30px;

      &:first-child {
        margin-right: 10px; } }

    .link-button {
      display: none; } } }

@media (max-width: 991px) {
  .cookie-consent {
    &__wrapper {
      display: block;

      p {
        line-height: 20px; } }

    &__content {
      .short {
        display: block; } }

    .detailed {
        display: none; }

    &__actions {
      margin-left: 0;
      justify-content: flex-start;

      .button {
        display: none; }

      .link-button {
        display: flex; } } } }
