@import "variables";

.stage {
  &.headline-text {
    margin-top: 100px;
    margin-bottom: 48px;

    p {
      font-weight: bold;
      font-size: 18px; }

    h1 {
      margin: 0 0 28px; }

    h2 {
      margin-top: 0px; }

    h1, h2, p {
      text-align: center; } }

  &.headline-only {
    h1 {
      text-align: center;
      margin: 80px 0; } } }

.wrapper-stage-menu {
  width: 100%;
  height: 60px;
  margin-top: 40px;
  overflow: hidden; }

.blog-image-stage {
  height: 450px;
  margin-top: -#{$menu-height-xs}px;
  background: white no-repeat center center;
  background-size: cover;

  text-align: center; }

.image-stage {
  height: 450px;
  margin-top: -#{$menu-height-xs}px;
  background: white no-repeat center center;
  background-size: cover;

  text-align: center;

  //.stage-logo
  //height: 96px
  //width: 96px
  //margin-top: 50%

  .stage-menu {
    width: 100%;
    height: 100px;
    margin-top: 0;
    white-space: nowrap;

    overflow-y: hidden;
    overflow-x: scroll;

    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none; }

    a {
      margin: 0 15px;
      color: white;

      &.active, &:hover {
        color: white;

        .menu-link-helper {
          display: inline-block;
          height: 34px;
          border-bottom: 3px solid #ffffff;
          color: #ffffff; } } }


    &.dark a {
      color: $default-font-color; } } }

@media (min-width: 992px) {

  .wrapper-stage-menu {
    width: 100%;
    height: 100px;
    margin-top: 0;
    overflow: hidden; }

  .stage {
    &.headline-text {
      margin-top: 120px;
      margin-bottom: 160px; } }

  .blog-image-stage {
    height: 680px;
    margin-top: -#{$menu-height-md}px;

    .stage-logo {
      margin-top: $menu-height-md + 160px; }

    .stage-menu {
      margin-top: 50px; } }

  .image-stage {
    height: 500px;
    margin-top: -#{$menu-height-md}px;

    .stage-logo {
      margin-top: $menu-height-md + 160px; }

    .stage-menu {
      margin-top: 50px; } } }
